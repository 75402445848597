<template>
  <div class="wlwsb">
    <!-- 物联网设备 -->
    <ul class="top">
      <li>
        <p>气象站</p>
        <span>
          {{deviceInfo.atmoCount}}
          <i>台</i>
        </span>
      </li>
      <li>
        <p>采集设备</p>
        <span>
          {{deviceInfo.envCount}}
          <i>台</i>
        </span>
      </li>
      <li>
        <p>监控设备</p>
        <span>
          {{deviceInfo.videoCount}}
          <i>台</i>
        </span>
      </li>
    </ul>
    <div class="content">
      <div class="left">
        <el-scrollbar style="height:100%;">
          <ul class="list">
            <li
              v-for="item in atmoEnvData"
              :key="item.id"
              :class="{'active': curDevice.id === item.id}"
              @click="getDeviceRealData(item)"
              :title="item.name"
            >{{item.name}}</li>
          </ul>
        </el-scrollbar>
      </div>
      <div class="right">
        <div class="title">{{curDevice.name}}</div>
        <div class="info">
          <el-carousel ref="cardShow" height="120px" :autoplay="false" arrow="never">
            <el-carousel-item v-if="realData.length">
              <div class="inner">
                <div
                  class="item"
                  v-for="env in realData.slice(0, 5)"
                  :key="env.envparamid"
                  @click="envDeviceChange(env)"
                >
                  <span>{{env.name}}</span>
                  <span>{{`${env.envvalue}${env.unit}`}}</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item v-if="!realData.length">
              <div class="inner">暂无数据</div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="date">
          <el-date-picker
            class="pub_input"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            v-model="date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <el-button slot="append" icon="el-icon-search" @click="searchOldData"></el-button>
        </div>
        <div class="chart" id="envChart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDeviceInfoByCompanyId,
  getDeviceInfoByCompany,
  getDeviceRealData,
  getDeviceOldData,
} from '@/api/zhsc'
import { SUCCESS } from '../../utils/config'
import { parseTime } from '@/utils/date'

export default {
  props: {
    // baseObj:{
    //     type:Object,
    //     default:()=>{{}}
    // },
    deviceData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curDevice: {},
      deviceInfo: {
        videoCount: 0,
        envCount: 0,
        atmoCount: 0,
      },
      realData: [],
      date: [], //
      atmoEnvData: [], // 气象和采集设备集合
      envChart: '', //
    }
  },
  mounted() {
    // this.init()
    this.initEnvChart()
  },
  watch: {
    // baseObj:{ //监听的对象
    //     immediate:true, //先执行一次
    //     deep:true, //深度监听设置为 true
    //     handler:function(newV){
    //         // console.log('变了',newV)
    //         this.init()
    //     }
    // },
    deviceData: {
      //监听的对象
      immediate: true, //先执行一次
      deep: true, //深度监听设置为 true
      handler: function (newV) {
        // console.log('变了',newV)
        let videoData = newV.filter((item) => item.devicetype === 'video')
        // 气象和采集
        let atmoData = newV.filter((item) => item.devicetype === 'atmo')
        let sensorData = newV.filter((item) => item.devicetype === 'sensor')
        // 控制柜
        let cabinetData = newV.filter((item) => item.devicetype === 'cabinet')
        // 环境监测
        let envData = newV.filter((item) => item.devicetype === 'sensor')

        //                this.atmoData = atmoData
        this.deviceInfo = {
          videoCount: videoData.length,
          envCount: envData.length,
          atmoCount: atmoData.length,
        }
        // 获取环境设备实时数据
        this.atmoEnvData = [...atmoData, ...sensorData]
        this.atmoEnvData.length && this.getDeviceRealData(this.atmoEnvData[0])
      },
    },
  },
  methods: {
    // init() {
    // //    getDeviceInfoByCompanyId({
    // //        companyId: this.baseObj.id
    // //    }, {
    // //        autp: 2,
    // //    })
    //     getDeviceInfoByCompany({
    //         companyName: this.baseObj.name,
    //     //    companyName: '南和县三多蔬菜专业合作社'
    //     }, {
    //         autp: 2
    //     }).then(res => {
    //         if (res.type === SUCCESS) {
    //             let deviceData = res.data
    //             let videoData = res.data.filter(item => item.devicetype === 'video')
    //             // 气象
    //             let atmoData = res.data.filter(item => item.devicetype === 'atmo')
    //             // 控制柜
    //             let cabinetData = res.data.filter(item => item.devicetype === 'cabinet')
    //             // 环境监测
    //             let envData = res.data.filter(item => item.devicetype ===  ('pests' || 'spore'))

    //             this.atmoData = atmoData
    //             this.deviceInfo = {
    //                 videoCount: videoData.length,
    //                 envCount: envData.length,
    //                 atmoCount: atmoData.length
    //             }
    //             // 获取环境设备实时数据
    //             this.atmoData.length && this.getDeviceRealData(this.atmoData[0])
    //         }
    //     })
    // },
    // 获取环境设备实时数据
    getDeviceRealData(device) {
      this.curDevice = device
      let param = {
        deviceId: device.id,
      }
      getDeviceRealData(
        {
          data: JSON.stringify(param),
        },
        {
          autp: 2,
        }
      ).then((res) => {
        if (res.type === SUCCESS) {
          let obj = {
            deviceId: device.id,
          }
          this.realData = res.data[obj.deviceId]
          // 获取设备历史数据
          const format = '{y}-{m}-{d}'
          let today = parseTime(Date.now(), format)
          let recentDay = parseTime(
            new Date(today) - 6 * 24 * 60 * 60 * 1000,
            format
          )
          this.date = [recentDay, today]
          this.envDeviceChange(this.realData[0], recentDay, today)
        }
      })
    },
    // 环境设备切换获取设备历史数据
    envDeviceChange(obj, recentDay, today) {
      this.curEnv = obj
      let recent = recentDay
      let now = today
      if (!recentDay) {
        const format = '{y}-{m}-{d}'
        let dateNow = parseTime(Date.now(), format)
        let dateRecent = parseTime(
          new Date(dateNow) - 6 * 24 * 60 * 60 * 1000,
          format
        )
        this.date = [dateRecent, dateNow]
        recent = dateRecent
        now = dateNow
      }
      let param = {
        stime: `${recent} 00:00:00.000`,
        etime: `${now} 23:59:59.999`,
        device: [
          {
            deviceId: obj.deviceid,
            envParamId: obj.envparamid,
          },
        ],
        page: 1,
        limit: 10000,
      }
      getDeviceOldData(
        {
          data: JSON.stringify(param),
        },
        {
          autp: 2,
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
      ).then((res) => {
        if (res.type === SUCCESS) {
          this.envChartReload(obj, res.data.data)
        }
      })
    },
    // 日期选择
    searchOldData() {
      this.date.length &&
        this.realData.length &&
        this.envDeviceChange(this.curEnv, this.date[0], this.date[1])
    },
    initEnvChart() {
      //环境信息
      this.envChart = this.$echarts.init(document.getElementById('envChart'))
      let legendData = ['土壤温度']
      let fontColor = '#B9C3D3'
      let option = {
        backgroundColor: 'transparent',
        grid: {
          left: '10%',
          right: '2%',
          top: '20%',
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: fontColor,
            },
          },
        },
        legend: {
          data: legendData,
          right: 0,
          top: '2%',
          textStyle: {
            color: fontColor,
            fontSize: 16,
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          data: [],
        },
        yAxis: {
          name: '土壤温度',
          nameTextStyle: {
            color: fontColor,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          type: 'value',
          //                    min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(51,91,171,.54)',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: legendData[0],
            type: 'line',
            //    smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            itemStyle: {
              color: '#00A8FF',
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0,168,255,0.4)',
                },
                {
                  offset: 1,
                  color: 'rgba(0,168,255,0)',
                },
              ]),
            },
            data: [],
          },
        ],
      }
      this.envChart.setOption(option)
    },
    envChartReload(env, data) {
      //环境信息重载
      this.envChart.setOption({
        legend: {
          data: [env.name || ''],
        },
        xAxis: {
          data: data.reverse().map((item) => item.examtime.substr(5, 5)),
        },
        yAxis: {
          name: env.unit || '',
        },
        series: [
          {
            name: env.name || '',
            data: data.map((item) => {
              return {
                name: item.name,
                value: item[env.envparamid],
              }
            }),
          },
        ],
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.wlwsb {
  // border: 1px solid #f00;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .top {
    // border: 1px solid #0f0;
    left: 150px;
    flex: none;
    display: flex;
    justify-content: space-around;
    li {
      width: 280px;
      height: 58px;
      background: url('~@/assets/images/dialog/wlwsb_bg.png') no-repeat
        center/100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-sizing: border-box;
      p {
        font-size: 18px;
      }
      span {
        font-size: 30px;
        color: #00fcff;
        i {
          font-size: 16px;
        }
      }
    }
  }
  .content {
    // border: 1px solid #f00;
    flex: 1;
    margin-top: 50px;
    background-image: linear-gradient(
      rgba(1, 43, 119, 0),
      rgba(1, 43, 119, 0.58)
    );
    display: flex;
    overflow: hidden;
    padding: 0 25px;
    box-sizing: border-box;
    .left {
      width: 150px;
      flex: none;
      // border: 1px solid #0f0;
      box-sizing: border-box;
      .list {
        // border: 1px solid #f0f;
        width: 150px;
        // box-sizing: border-box;
        li {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url('~@/assets/images/dialog/wlwsb_menu.png') no-repeat
            center/100% 100%;
          margin-top: 25px;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        li:first-child {
          margin-top: 0;
        }
        .active {
          background: url('~@/assets/images/dialog/wlwsb_menu.active.png')
            no-repeat center/100% 100%;
        }
      }
    }
    .right {
      flex: 1;
      margin-left: 65px;
      display: flex;
      flex-direction: column;
      .title {
        width: 510px;
        height: 50px;
        background: url('~@/assets/images/dialog/wlwsb_name.png') no-repeat
          center/100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: 700;
        margin: 0 auto;
      }
      .info {
        height: 120px;
        margin: 15px 0;
        background: url('~@/assets/images/zsk_border.png') no-repeat center/100%
          100%;
        position: relative;
        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          z-index: 4;
        }
        .left-arrow {
          left: 10px;
        }
        .right-arrow {
          right: 10px;
        }
        ::v-deep .el-carousel__button {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #25b0ff;
        }
        .inner {
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 100%;
          .item {
            display: flex;
            flex-direction: column;
            > span:first-child {
              font-size: 16px;
              color: #fff;
            }
            > span:last-child {
              font-size: 22px;
              color: #0cfed9;
              margin-top: 15px;
            }
          }
        }
      }
      .date {
        text-align: center;
        margin-top: 15px;
        // border: 1px solid #0f0;
        .pub_input {
          ::v-deep .el-input__inner {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .el-button {
          background-color: #1890ff;
          border: 1px solid #1890ff;
          color: #fff;
          font-size: 16px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .chart {
        // border: 1px solid #0f0;
        flex: 1;
        box-sizing: border-box;
      }
    }
  }
}
</style>