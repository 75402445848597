<template>
  <div class="jbxx">
    <!-- 基本信息 -->
    <el-scrollbar style="height:100%;">
      <div class="wrap">
        <baidu-map
          class="baidu_map"
          ak="X8nUXVn3fCddrPcgNPwG5Wsn8YGlLu88"
          :center="center"
          :zoom="zoom"
          @ready="handler"
          :mapStyle="mapStyle"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-marker
            :position="center"
            animation="BMAP_ANIMATION_BOUNCE"
            :icon="{url: markerUrl, size: {width: 300, height: 157}}"
          ></bm-marker>
        </baidu-map>
        <p>{{baseObj.introduce}}</p>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation.vue'
import styleJson from '@/assets/js/custom_map_config.json'
export default {
  components: { BaiduMap, BmMarker, BmNavigation },
  props: {
    baseObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 6,
      markerUrl: require('@/assets/images/dialog/site_marker.png'),
      mapStyle: {
        styleJson,
      },
    }
  },
  watch: {
    baseObj: {
      //监听的对象
      immediate: true, //先执行一次
      deep: true, //深度监听设置为 true
      handler: function (newV) {
        // console.log('变了',newV)
        // console.log(JSON.parse(JSON.stringify(this.baseObj)))
        if (newV.latitude && newV.longitude) {
          this.center.lng = newV.longitude
          this.center.lat = newV.latitude
          this.zoom = 15
        }
      },
    },
  },
  mounted() {},
  methods: {
    handler({ BMap, map }) {
      this.$nextTick(() => {
        map.setMapStyle({ style: 'googlelite' })
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.jbxx {
  // border: 1px solid #f00;
  height: 100%;
  .wrap {
    padding: 0 20px;
    box-sizing: border-box;
    .baidu_map {
      width: 100%;
      height: 500px;
      // border: 1px solid #f00;
      margin-bottom: 20px;
    }
    img {
      max-width: 100%;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.8;
    }
  }
  // overflow: hidden;
}
::v-deep .anchorBL {
  display: none !important;
}
</style>
