<template>
    <div class="zzqk">
        <!-- 种植情况 -->
        <div class="table_list">
            <el-table :data="tableData" stripe class="pub_table" height="100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="varietiesName" label="品种" min-width="100" align="center"></el-table-column>
                <el-table-column prop="area" label="面积" min-width="100" align="center"></el-table-column>
                <el-table-column prop="plantTime" label="时间" min-width="100" align="center"></el-table-column>
            </el-table>
        </div>
        <el-pagination
            class="pub_laypage" 
            :page-size="10"
            @current-change="currChange"
            :current-page.sync="currPage"
            prev-text="上一页"
            next-text="下一页"
            :pager-count="11"
            layout="prev, pager, next,total"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
// import {plantInfo} from '@/api/dialog' //接口
export default {
    props:{
        // baseObj:{
        //     type:Object,
        //     default:()=>({})
        // },
        tableData:{
            type:Array,
            default:()=>[]
        },
        total:{
            type:Number,
            default:0
        },
        pageNum:{
            type:Number,
            default:1
        },
    },
    data(){
        return {
            // tableData: [],
            currPage:this.pageNum,
            // pageSize:10,
            // total:0,
        }
    },
    watch:{
        pageNum(newV){
            this.currPage=newV
        }
    //     baseObj:{ //监听的对象
    //         immediate:true, //先执行一次
    //         deep:true, //深度监听设置为 true
    //         handler:function(newV){
    //             this.plantInfo()
    //         }
    //     }
    },
    methods:{
        currChange(val){
            this.$emit('currChange',val)
        }
        // plantInfo(val){//企业种植情况详情
        //     this.pageNum=val||1
        //     plantInfo({
        //         companyId:this.baseObj.id,//企业id
        //         pageSize:this.pageSize,//
        //         pageNum:this.pageNum,//
        //     }).then(res=>{
        //         // console.log('企业种植情况详情')
        //         // console.log(JSON.parse(JSON.stringify(res.rows)))
        //         this.tableData=res.rows||[]
        //         this.total=res.total||0
        //     }).catch(()=>{
        //         this.tableData=[]
        //         this.total=0
        //     })
        // },
    }
}
</script>

<style lang='scss' scoped>
    .zzqk{
        height: 100%;
        display: flex;
        flex-direction: column;
        .table_list{
            flex: 1;
            overflow: hidden;
            margin-bottom: 10px;
        }
    }
</style>