<template>
    <div class="qypp">
        <!-- 企业品牌 -->
        <div class="left">
            <el-scrollbar style="height:100%;">
                <ul class="list">
                    <li @click="selectMenu(item)" :class="{active:selectIndex==item.id}" v-for="item in ppData" :key="item.id">{{item.name}}</li>
                </ul>
            </el-scrollbar>
        </div>
        <div class="right">
            <div class="title">{{currInfo.name}}</div>
            <div class="info">
                <!-- <el-carousel :interval="4000" type="card" height="320px" :autoplay="false" arrow="never" indicator-position="none">
                    <el-carousel-item v-for="item in 6" :key="item"> -->
                        <div class="img_wrap">
                            <img :src="imageUrl+currInfo.imgUrl" alt="">
                            <p>{{currInfo.name}}</p>
                        </div>
                    <!-- </el-carousel-item>
                </el-carousel> -->
            </div>
            <ul class="list">
                <li>
                    <label>品牌名称：</label>
                    <span>{{currInfo.name}}</span>
                </li>
                <li>
                    <label>类型：</label>
                    <span>{{currInfo.brandType}}</span>

                    <label>获取时间：</label>
                    <span>{{currInfo.createTime}}</span>
                </li>
                <!-- <li>
                    <label>获取时间：</label>
                    <span>2021-05-16   14:56:19</span>
                </li> -->
            </ul>
        </div>
    </div>
</template>
<script>
// import {brandInfo} from '@/api/dialog' //接口
// import { imageUrl } from '@/utils/config'
export default {
    props:{
        // baseObj:{
        //     type:Object,
        //     default:()=>({})
        // },
        ppData:{
            type:Array,
            default:()=>[]
        },
    },
    data(){
        return {
            ppData: [],
            selectIndex: '',
            currInfo: {},
            imageUrl:sysUrl,
        }
    },
    watch:{
        // baseObj:{ //监听的对象
        //     immediate:true, //先执行一次
        //     deep:true, //深度监听设置为 true
        //     handler:function(newV){
        //         // console.log('变了',newV)
        //         this.brandInfo()
        //     }
        // },
        ppData:{ //监听的对象
            immediate:true, //先执行一次
            deep:true, //深度监听设置为 true
            handler:function(newV){
                // console.log('变了',newV)
                if (newV.length!=0) {
                    this.selectIndex=newV[0].id
                    this.currInfo=newV[0]
                }
            }
        }
    },
    methods:{
        // brandInfo(){//企业品牌
        //     brandInfo({
        //         companyId:this.baseObj.id,//企业id
        //     },{
        //         token: window.sessionStorage.token
        //     }).then(res=>{
        //         // console.log('企业品牌')
        //         // console.log(JSON.parse(JSON.stringify(res.rows)))
        //         this.ppData=res.rows||[]
        //         if (this.ppData.length!=0) {
        //             this.selectIndex=this.ppData[0].id
        //             this.currInfo=this.ppData[0]
        //         }
        //     })
        // },
        selectMenu(obj){
            this.selectIndex=obj.id
            this.currInfo=obj
        },
        clearData(){
            this.selectIndex=''
            this.currInfo={}
        }
    }
}
</script>

<style lang='scss' scoped>
    .qypp{
        // border: 1px solid #f00;
        height: 100%;
        background-image: linear-gradient(rgba(1,43,119,0), rgba(1,43,119,.58));
        display: flex;
        overflow: hidden;
        padding: 0 25px;
        box-sizing: border-box;
        .left{
            width: 150px;
            flex: none;
            .list{
                // border: 1px solid #f0f;
                // box-sizing: border-box;
                width: 150px;
                li{
                    width: 100%;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url('~@/assets/images/dialog/wlwsb_menu.png') no-repeat center/100% 100%;
                    margin-top: 25px;
                    cursor: pointer;
                }
                li:first-child{
                    margin-top: 0;
                }
                .active{
                    background: url('~@/assets/images/dialog/wlwsb_menu.active.png') no-repeat center/100% 100%;
                }
            }
        }
        .right{
            flex:1;
            margin-left: 65px;
            display: flex;
            flex-direction: column;
            .title{
                width: 510px;
                height: 50px;
                background: url('~@/assets/images/dialog/wlwsb_name.png') no-repeat center/100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                font-weight: 700;
                margin: 0 auto;
            }
            .info{
                margin-top: 30px;
                .img_wrap{
                    flex: none;
                    width: 425px;
                    margin: 0 auto;
                    height: 320px;
                    font-size: 0;
                    background: url('~@/assets/images/dialog/qyxx_border.png') no-repeat center/100% 100%;
                    padding: 15px 10px 10px 18px;
                    box-sizing: border-box;
                    position: relative;
                    p{
                        position: absolute;
                        left: 18px;
                        right: 10px;
                        bottom: 10px;
                        font-size: 14px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        background-color: rgba(0,0,0,.57);
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .list{
                margin: 0 auto;
                li{
                    font-size: 20px;
                    margin-top: 30px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    label{
                        color: #fff;
                    }
                    span{
                        color: #10C4FF;
                    }
                }
                li:nth-child(2){
                    label:nth-child(3){
                        margin-left: 30px;
                    }
                }
            }
        }
    }
</style>