<template>
    <div class="zzqk">
        <!-- 种植情况 -->
        <div class="table_list">
            <el-table :data="supplyData" stripe class="pub_table" height="100%">
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="plantName" label="品种" min-width="100" align="center"></el-table-column>
                <el-table-column prop="companyName" label="企业" min-width="100" align="center"></el-table-column>
                <el-table-column prop="recovery_time" label="采收时间" width="200" align="center"></el-table-column>
                <el-table-column prop="yield" label="产量" width="150" align="center"></el-table-column>
            </el-table>
        </div>
        <el-pagination
            class="pub_laypage" 
            :page-size="10"
            @current-change="currChange"
            :current-page.sync="currPage"
            prev-text="上一页"
            next-text="下一页"
            :pager-count="11"
            layout="prev, pager, next,total"
            :total="supplyTotal">
        </el-pagination>
    </div>
</template>

<script>
// import {plantInfo} from '@/api/dialog' //接口
export default {
    props:{
        // baseObj:{
        //     type:Object,
        //     default:()=>({})
        // },
        supplyData:{
            type:Array,
            default:()=>[]
        },
        supplyTotal:{
            type:Number,
            default:0
        },
        supplyPageNum:{
            type:Number,
            default:1
        },
    },
    data(){
        return {
            // supplyData: [],
            currPage:this.supplyPageNum,
            // pageSize:10,
            // total:0,
        }
    },
    watch:{
        supplyPageNum(newV){
            this.currPage=newV
        }
    //     baseObj:{ //监听的对象
    //         immediate:true, //先执行一次
    //         deep:true, //深度监听设置为 true
    //         handler:function(newV){
    //             this.plantInfo()
    //         }
    //     }
    },
    methods:{
        currChange(val){
            this.$emit('currSupplyChange',val)
        }
    }
}
</script>

<style lang='scss' scoped>
    .zzqk{
        height: 100%;
        display: flex;
        flex-direction: column;
        .table_list{
            flex: 1;
            overflow: hidden;
            margin-bottom: 10px;
        }
    }
</style>