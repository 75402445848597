<template>
    <div class="enterprise_info">
        <base-dialog
            width="1560px"
            :label="baseObj.name||'企业详情'"
            v-show="dialogFlag"
            :z-index="zIndex"
            :show-close="true"
            @closeDialog="closeDialog"
        >
            <div class="enterprise_wrap">
                <div class="enterprise_left">
                    <div class="img_wrap">
                        <img @click="arrowClick('left')" src="~@/assets/images/left_arrow.png" class="arrow left_arrow" alt="">
                        <el-carousel ref="cardShow" height="275px" indicator-position="none" :autoplay="false" arrow="never">
                            <el-carousel-item v-for="item in comImgs" :key="item">
                                <img :src="sysUrl+item" @error="e => { e.target.src = defaultImg }" alt="">
                                <p>{{baseObj.varieties||''}}</p>
                            </el-carousel-item>
                        </el-carousel>
                        <img @click="arrowClick('right')" src="@/assets/images/right_arrow.png" class="arrow right_arrow" alt="">
                    </div>
                    <div class="info_wrap">
                        <el-scrollbar style="height:100%;">
                            <ul>
                                <li v-show="baseObj.name">
                                    <label>基地名称：</label>
                                    <span>{{baseObj.name||''}}</span>
                                </li>
                                <li v-show="baseObj.contacts">
                                    <label>联系人：</label>
                                    <span>{{baseObj.contacts||''}}</span>
                                </li>
                                <li v-show="baseObj.plantArea&&baseObj.plantArea!=0">
                                    <label>种植面积：</label>
                                    <span>{{baseObj.plantArea||''}}亩</span>
                                </li>
                                <li v-show="baseObj.varieties">
                                    <label>种植品种：</label>
                                    <span>{{baseObj.varieties||''}}</span>
                                </li>
                                <li v-show="baseObj.params&&baseObj.params.monthSupply&&baseObj.params.monthSupply!=0">
                                    <label>每月供应信息：</label>
                                    <span>{{baseObj.params?(baseObj.params.monthSupply):''}}吨</span>
                                </li>
                                <li v-show="baseObj.address">
                                    <label>地址：</label>
                                    <span>{{baseObj.address||''}}</span>
                                </li>
                                <li @click="toNumEnterprise">
                                    <span class="buttonView">数字企业</span>
                                </li>
                            </ul>
                        </el-scrollbar>
                    </div>
                </div>
                <div class="enterprise_content">
                    <ul class="tab_head">
                        <li
                            v-show="item.show"
                            :class="{active:currPage==item.url}"
                            v-for="item in tabList"
                            :key="item.title"
                            @click="goTo(item.url)">{{item.title}}
                        </li>
                        <!-- <li class="active" @click="currPage='Jbxx'">基本信息</li>
                        <li>种植情况</li>
                        <li>供应情况</li>
                        <li>物联网设备</li> -->
                    </ul>
                    <div class="tab_body">
                        <keep-alive>
                            <component
                                :is="currPage"
                                :baseObj="baseObj"
                                :total="total"
                                :supplyTotal="supplyTotal"
                                :pageNum="pageNum"
                                :supplyPageNum="supplyPageNum"
                                @currChange="currChange"
                                @currSupplyChange="currSupplyChange"
                                :deviceData="deviceData"
                                :ppData="ppData"
                                :supplyData="supplyData"
                                :tableData="tableData">
                            </component>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </base-dialog>
    </div>
</template>

<script>
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import Jbxx from '@/components/enterprise/jbxx' //基本信息
import Zzqk from '@/components/enterprise/zzqk' //种植情况
import Gyqk from '@/components/enterprise/gyqk' //供应情况
import Wlwsb from '@/components/enterprise/wlwsb' //物联网设备
import Qypp from '@/components/enterprise/qypp' //企业品牌
import {articleDetail,plantInfo,brandInfo,supplyList} from '@/api/dialog' //接口
import {getDeviceInfoByCompany} from '@/api/zhsc'
import { SUCCESS } from '../../utils/config'
export default {
    components:{BaseDialog,Jbxx,Zzqk,Gyqk,Wlwsb,Qypp},
    props:{
        zIndex:{
            type:Number,
            default:103,
        },
    },
    name:'EnterpriseInfo',
    data(){
        return {
            currPage:'Jbxx',
            tabList:[
                {
                    title:'基本信息',
                    url:'Jbxx',
                    show:true,
                },
                {
                    title:'种植情况',
                    url:'Zzqk',
                    show:true,
                },
                {
                    title:'供应情况',
                    url:'Gyqk',
                    show:true,
                },
                {
                    title:'物联网设备',
                    url:'Wlwsb',
                    show:true,
                },
                {
                    title:'企业品牌',
                    url:'Qypp',
                    show:true,
                },
            ],
            currObj:{},//当前信息
            dialogFlag:false,
            baseObj:{},//基本信息
            defaultImg:require('@/assets/images/default_img.png'),

            tableData: [],//种植情况
            pageNum:1,
            total:0,

            supplyData: [],//供应情况
            supplyPageNum:1,
            supplyTotal:0,

            deviceData:[],//物联网设备
            ppData:[],//企业品牌
            sysUrl,
        }
    },
    computed:{
        comImgs(){
            if (this.baseObj.comImgs&&this.baseObj.comImgs.length!=0) {
                return this.baseObj.comImgs
            }else{
                return [this.defaultImg]
            }
        }
    },
    methods:{
        baseInfo(){//企业详情
            articleDetail(this.currObj.id).then(res=>{
                // console.log('企业详情')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                this.baseObj=res.data||{}
            })
        },
        toNumEnterprise(){
            let token = window.sessionStorage.token;
            console.log(this.baseObj)
            var a = `http://47.94.107.242:35015/digitalEnterprise/#/homes?orgid=${this.baseObj.id}&areacode=${this.baseObj.areaCode.substring(0,6)}&shortname=${this.baseObj.name}&title=数字企业&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4ZjY0ODE0MGY2MThmYjk1OTk1OTQ4ZjU5MDEwNTI2NCIsInVzZXJuYW1lIjoi6KGh5rC05biC5YWo5Lqn5Lia6ZO-566h55CG5ZGYIn0.DKXYI-PfCd-BWDe_nFS-57Lp02GSaepHgCfs-hXtK38`

            // var a = `http://47.94.155.198:35400/digital_corp_v2/#/standardPlant?orgInfoId=${this.baseObj.id}&orgInfoName=${this.baseObj.name}&token=${token}`;
            window.open(a,'_blank')
        },
        plantInfo(val){//企业种植情况详情
            this.pageNum=val||1
            plantInfo({
                companyId:this.currObj.id,//企业id
                pageSize:10,//
                pageNum:this.pageNum,//
            }).then(res=>{
                // console.log('企业种植情况详情')
                // console.log(JSON.parse(JSON.stringify(res.rows)))
                this.tableData=res.rows||[]
                this.total=res.total||0
                if (this.tableData.length==0) {
                    this.tabList[1].show=false
                }else{
                    this.tabList[1].show=true
                }
            }).catch(()=>{
                this.tableData=[]
                this.total=0
            })
        },
        supplyList(page){//供应列表
            this.supplyPageNum=page||1
            supplyList({
                pageSize:10,
                pageNum:this.supplyPageNum,
                companyName:this.currObj.name,
            }).then(res=>{
                // console.log('供应列表')
                // console.log(JSON.parse(JSON.stringify(res.rows)))
                this.supplyData=res.rows||[]
                this.supplyTotal=res.total||0
                if (this.supplyData.length==0) {
                    this.tabList[2].show=false
                }else{
                    this.tabList[2].show=true
                }
            }).catch(()=>{
                this.supplyData=[]
                this.supplyTotal=0
            })
        },
        getDeviceInfoByCompany(){//物联网设备
            getDeviceInfoByCompany({
                companyName: this.currObj.name,
            //    companyName: '南和县三多蔬菜专业合作社'
            }, {
                autp: 2
            }).then(res => {
                // console.log('物联网设备')
                // console.log(JSON.parse(JSON.stringify(res.data)))
                if (res.type === SUCCESS) {
                    this.deviceData = res.data
                    if (this.deviceData.length==0) {
                        this.tabList[3].show=false
                    }else{
                        this.tabList[3].show=true
                    }
                }else{
                    this.tabList[3].show=false
                }
            })
        },
        brandInfo(){//企业品牌
            brandInfo({
                companyId:this.currObj.id,//企业id
            },{
                token: window.sessionStorage.token
            }).then(res=>{
                // console.log('企业品牌')
                // console.log(JSON.parse(JSON.stringify(res.rows)))
                this.ppData=res.rows||[]
                if (this.ppData.length==0) {
                    this.tabList[4].show=false
                }else{
                    this.tabList[4].show=true
                }
            })
        },
        currChange(val){//种植情况切换
            this.plantInfo(val)
        },
        currSupplyChange(val){//供应情况切换
            this.supplyList(val)
        },
        closeDialog(){//关闭弹框
            this.dialogFlag=false
            this.currPage='Jbxx'
            // this.$emit('closeDialog')
        },
        show(obj){//显示
            this.dialogFlag=true
            this.currObj=obj
            if (obj.company_id) {
                this.currObj.id=obj.company_id
            }
            this.baseInfo()
            this.plantInfo()
            this.supplyList()
            this.getDeviceInfoByCompany()
            this.brandInfo()
        },
        goTo(url){//跳转
            this.currPage=url
        },
        arrowClick(val) {//上下切换
            if (val === "right") {
                this.$refs.cardShow.next();
            } else {
                this.$refs.cardShow.prev();
            }
        },
    }
}
</script>

<style lang='scss' scoped>
    .enterprise_info{
        .enterprise_wrap{
            // border: 5px solid #f00;
            box-sizing: border-box;
            height: 100%;
            overflow: hidden;
            padding: 20px 30px;
            display: flex;
            .enterprise_left{
                flex: none;
                width: 435px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .img_wrap{
                    flex: none;
                    width: 100%;
                    height: 300px;
                    font-size: 0;
                    background: url('~@/assets/images/dialog/qyxx_border.png') no-repeat center/100% 100%;
                    padding: 15px 10px 10px 18px;
                    box-sizing: border-box;
                    position: relative;
                    p{
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 10px;
                        font-size: 14px;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        background-color: rgba(0,0,0,.57);
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    .arrow{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        width: 15px;
                        height: 21px;
                        z-index: 9999;
                    }
                    .left_arrow{
                        left:20px;
                    }
                    .right_arrow{
                        right: 20px;
                    }
                }
                .info_wrap{
                    flex: 1;
                    margin-top: 40px;
                    ul{
                        >li{
                            font-size: 20px;
                            margin-top: 30px;
                            padding: 0 10px;
                            box-sizing: border-box;
                            label{
                                color: #fff;
                            }
                            span{
                                color: #10C4FF;
                            }
                        }
                        li:first-child{
                            margin-top: 0;
                        }
                    }
                }
            }
            .enterprise_content{
                flex: 1;
                // border: 3px solid #f0f;
                box-sizing: border-box;
                margin-left: 25px;
                display: flex;
                flex-direction: column;
                .tab_head{
                    // border: 2px solid #f00;
                    flex: none;
                    display: flex;
                    justify-content: center;
                    padding-top: 15px;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    li{
                        cursor: pointer;
                        width: 145px;
                        height: 36px;
                        margin: 0 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        background: url('~@/assets/images/dialog/qyxx_tab.png') no-repeat center/100% 100%;
                    }
                    .active{
                        background: url('~@/assets/images/dialog/qyxx_tab_active.png') no-repeat center/100% 100%;
                    }
                }
                .tab_body{
                    // border: 2px solid #0f0;
                    flex: 1;
                    overflow: hidden;
                }
            }
        }
    }
    .buttonView {
        color: #ffffff!important;
        padding: 6px 20px;
        cursor: pointer;
        background: url('~@/assets/images/dialog/qyxx_tab.png') no-repeat center/100% 100%;
        &:hover {
            background: url('~@/assets/images/dialog/qyxx_tab_active.png') no-repeat center/100% 100%;

        }
    }
</style>
